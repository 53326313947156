import ResponsiveImage from "../modules/responsive-image";
import Button from "../button";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import Card from "../card";

export default function cbCards({block}) {
    const swiperSettings = {
        slidesPerView: 1.1,
        spaceBetween: 0,
    };

    const hasMultipleRows = block.cards && block.cards.length >= 4;

    return (
        <>
            <div className="container">
                <div className="frame frame--justify-sm-center">
                    <div className="bit-xxs-12">
                        {block.title && (
                            <div
                                className="text-center tw-pb-6"
                                dangerouslySetInnerHTML={{__html: block.title}}
                            />
                        )}

                        {block.text && (
                            <div
                                className="tw-text-center tw-max-w-[900px] tw-mx-auto tw-font-normal tw-pb-16"
                                dangerouslySetInnerHTML={{__html: block.text}}/>
                        )}
                    </div>
                    <div className="bit-xxs-12">
                        {/* Mobile Slider */}
                        <div className="md:tw-hidden">
                            <Swiper
                                modules={[Pagination]}
                                {...swiperSettings}
                                pagination={{clickable: true}}
                            >
                                {block.cards && block.cards.length &&
                                    block.cards.map((card) => (
                                        <SwiperSlide key={card.title} className="!tw-h-auto tw-p-[15px]">
                                            <Card
                                                title={card.title}
                                                text={card.text}
                                                image={card.image}
                                                link={card.link}
                                            />
                                        </SwiperSlide>
                                    ))}
                            </Swiper>
                        </div>

                        {/* Desktop Grid */}
                        <div className={`tw-hidden md:tw-grid ${block.columns === '3' ? 'md:tw-grid-cols-3' : 'md:tw-grid-cols-2'} tw-gap-8 ${hasMultipleRows ? 'tw-pb-[70px]' : 'tw-pb-[30px]'}`}>
                            {block.cards && block.cards.length &&
                                block.cards.map((card, index) => (
                                    <div
                                        key={card.title}
                                        className={`${hasMultipleRows && block.columns !== '3' && index % 2 !== 0 ? 'tw-translate-y-[50px]' : ''}`}
                                    >
                                        <Card
                                            title={card.title}
                                            text={card.text}
                                            image={card.image}
                                            link={card.link}
                                        />
                                    </div>
                                ))}
                        </div>

                        {block.button && (
                            <div className="tw-flex tw-justify-center tw-pt-10">
                                <Button button={block.button} classes="button button--blue"/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
