import Button from "../button";
import ResponsiveImage from "../modules/responsive-image";

export default function CbBanner9({block}) {
    return (
        <>
            <div className="container pt-30 pt-sm-50 pt-md-75">
                <div
                    className="frame frame--justify-sm-center frame--justify-md-flex-start frame--justify-lg-space-between">
                    <div className="bit-xxs-12 bit-sm-9 bit-md-9 bit-lg-7">

                        {block.title && (
                            <div
                                className="cb--banner_9__title"
                                dangerouslySetInnerHTML={{__html: block.title}}
                            />
                        )}

                        {block.text && (
                            <div
                                className="tw-font-normal tw-pb-10"
                                dangerouslySetInnerHTML={{__html: block.text}}
                            />
                        )}

                        <div className="tw-flex tw-flex-wrap tw-gap-6">
                            {block.buttons && block.buttons.map((button, index) => (
                                <Button key={index} button={button.button}
                                        classes="button button--blue"/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
