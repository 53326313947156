import Link from "next/link";
import ResponsiveImage from "./modules/responsive-image";

export default function Card({title, text, image, link}) {
    return (
        <div
            className="tw-flex tw-flex-col tw-h-full tw-bg-white tw-relative tw-border-8 tw-border-white tw-border-solid tw-rounded-[25px] tw-overflow-hidden tw-shadow-xl">
            {image && <figure className="tw-relative tw-aspect-[3/2] tw-border-4 tw-border-red-500 tw-overflow-hidden">
                <ResponsiveImage
                    image={image}
                    layout="fill"
                    classes="tw-object-cover"
                />
            </figure>}

            <div className="tw-p-10 tw-pb-12 tw-grow tw-flex tw-flex-col tw-justify-between tw-items-start">
                <div className="tw-pb-4">
                    {title && <p className="tw-font-bold tw-text-[32px] tw-leading-tight tw-text-blue-dark">{title}</p>}

                    {text && <div className="tw-text-black tw-text-[16px] tw-leading-relaxed"
                                  dangerouslySetInnerHTML={{__html: text}}/>}
                </div>

                {link && link.url &&
                    <Link href={link.url} passHref>
                        <a target={link.target}
                           className="tw-text-blue-dark tw-uppercase tw-font-bold tw-no-underline tw-border-b-2 tw-border-b-blue-dark tw-border-solid tw-text-[14px] hover:tw-text-blue hover:tw-border-blue">
                            {link.title}
                        </a>
                    </Link>
                }
            </div>
        </div>

    );
}
